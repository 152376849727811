import React from "react";
import "./Sidenav.scss";
import dashboard64 from "../../../assets/images/icons/dashboard/dashboard64.png";
import payin64 from "../../../assets/images/icons/pay-in/payin64.png";
import payout64 from "../../../assets/images/icons/pay-out/payout64.png";
import baaswhite from "../../../assets/images/delcaper/just-delcaper.png";
import bblack from "../../../assets/images/delcaper/apple-touch-icon.png";
import { NavLink, Link } from "react-router-dom";
import { IoIosArrowDown } from "react-icons/io";

const Sidenav = () => {
  let userLogo = sessionStorage.getItem('partnerLogo')
  const openSubmenu = (e) => {
    e.currentTarget.nextSibling.classList.toggle("show");
  };

  let logoUser = "https://epmoney.easypay.co.in/web-mpm/guest/getSliderImages?documentPath=10182/0001/android/ventive/images/4.jpg"

  // Close all sidenav submenu when clicked outside
  window.addEventListener("click", (event) => {
    let sidenav = document.getElementById("sidenav");
    if (sidenav) {
      if (!event.target.closest(".nav-area")) {
        navArea();
      }
      if (!event.target.closest(".right-nav-toggler")) {
        sideNavArea(sidenav, event);
      }
    }
  });

  const navArea = ()=> {
    let submenus = document.getElementsByClassName("sub-menu-content");
        for (const element of submenus) {
          let openSubmenu = element;
          if (openSubmenu.classList.contains("show")) {
            openSubmenu.classList.remove("show");
          }
        }
  }

  const sideNavArea = (sidenav, event)=> {
    if (!event.target.closest(".open-submenu-btn")) {
      if (sidenav.classList.contains("right-nav")) {
        sidenav.classList.remove("right-nav");
      }
    }
  }

  return (
    <nav id="sidenav">
      <div className="sidenav-brand-wrapper fixed-top d-none d-lg-flex justify-content-center align-items-center">
        <Link to="/home">
          <div className="brand-logo">
              <img height="28" src={userLogo} alt="logo" />
          </div>
          {/* <div className="brand-logo-mini">
            <img height="28" src={userLogo} alt="logo" />
          </div> */}
        </Link>
      </div>
      <ul className="navbar-nav nav-area">
        <li className="nav-item">
          <NavLink to="/home" className="nav-link">
            <span className="menu-icon">
              <img width="20" height="20" src={dashboard64} alt="" />
            </span>
            <span className="menu-title">Home</span>
          </NavLink>
        </li>

        <li className="nav-item">
          <div className="nav-link open-submenu-btn" onClick={openSubmenu}>
            <span className="menu-icon">
              <img width="20" height="20" src={payin64} alt="" />
            </span>
            <span className="menu-title">
              Pay-Ins
              <span id="down-arrow" className="arrow">
                <IoIosArrowDown />
              </span>
            </span>
          </div>
          <div className="sub-menu-content">
            <ul className="navbar-nav sub-menu">
              <li className="sub-nav-item sub-active">
                <NavLink
                  to="/payIn/summary"
                  className="sub-nav-link"
                  state={{ data: "payIn" }}
                >
                  Summary
                </NavLink>
              </li>
              <li className="sub-nav-item">
                <NavLink to="/payIn/transaction" className="sub-nav-link">
                  Transactions
                </NavLink>
              </li>
            </ul>
          </div>
        </li>

        <li className="nav-item">
          <div className="nav-link open-submenu-btn" onClick={openSubmenu}>
            <span className="menu-icon">
              <img width="20" height="20" src={payout64} alt="" />
            </span>
            <span className="menu-title">
              Pay-Outs
              <span id="down-arrow" className="arrow">
                <IoIosArrowDown />
              </span>
            </span>
          </div>
          <div className="sub-menu-content">
            <ul className="navbar-nav sub-menu">
              <li className="sub-nav-item">
                <NavLink
                  to="/payOut/summary"
                  className="sub-nav-link"
                  state={{ data: "payOut" }}
                >
                  Summary
                </NavLink>
              </li>
              <li className="sub-nav-item">
                <NavLink to="/payOut/wallet" className="sub-nav-link">
                  Wallet
                </NavLink>
              </li>
              <li className="sub-nav-item">
                <NavLink to="/payOut/beneficiary" className="sub-nav-link">
                  Beneficiary
                </NavLink>
              </li>
              <li className="sub-nav-item">
                <NavLink to="/payOut/transaction" className="sub-nav-link">
                  Transactions
                </NavLink>
              </li>
            </ul>
          </div>
        </li>
        {/* vender */}
        {/* <li className="nav-item">
          <div className="nav-link open-submenu-btn" onClick={openSubmenu}>
            <span className="menu-icon">
              <img width="20" height="20" src={payout64} alt="" />
            </span>
            <span className="menu-title">
              Vendor
              <span id="down-arrow" className="arrow">
                <IoIosArrowDown />
              </span>
            </span>
          </div>
          <div className="sub-menu-content">
            <ul className="navbar-nav sub-menu">
              <li className="sub-nav-item">
                <NavLink to="/vendor/ledger" className="sub-nav-link">
                  Ledger
                </NavLink>
              </li>
            </ul>
          </div>
        </li> */}
        {/* <li className="nav-item">
          <div className="nav-link open-submenu-btn" onClick={openSubmenu}>
            <span className="menu-icon">
              <img width="20" height="20" src={payout64} alt="" />
            </span>
            <span className="menu-title">
              Status
              <span id="down-arrow" className="arrow">
                <IoIosArrowDown />
              </span>
            </span>
          </div>
          <div className="sub-menu-content">
            <ul className="navbar-nav sub-menu">
              <li className="sub-nav-item">
                <NavLink
                  to="/status/transfer"
                  className="sub-nav-link"
                >
                  Transfer
                </NavLink>
              </li>
              <li className="sub-nav-item">
                <NavLink
                  to="/status/beneficiary"
                  className="sub-nav-link"
                >
                  Beneficiary
                </NavLink>
              </li>
            </ul>
          </div>
        </li> */}
      </ul>
    </nav>
  );
};

export default Sidenav;
