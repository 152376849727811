import React, { Suspense, useContext, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import './App.scss';
import Home from './components/home/Home';
import Baas from './components/Login/Baas';
import Spinner from './components/Common/Loading/Spinner';
import AuthContext from './Store/auth-context';

import Favicon from "../src/assets/images/favicon.ico";

//Screens
const Login = React.lazy(() => import('./components/Login/CredentialsFolder/Login'));
const ResetPass = React.lazy(() => import('./components/Login/CredentialsFolder/ResetPass/ResetPass'));
const ForgetPassword = React.lazy(() => import('./components/Login/CredentialsFolder/ForgetPassword/ForgetPassword'));
const Profile = React.lazy(() => import('./components/home/pages/profile/Profile'));
const ChangePassword = React.lazy(() => import('./components/home/pages/profile/ChangePassword/ChangePassword'));
const Otp = React.lazy(() => import('./components/Login/CredentialsFolder/Otp/otp'));

//Pages
const HomePage = React.lazy(() => import('./components/home/pages/HomePage/HomePage'));
const Summary = React.lazy(() => import('./components/Common/Summary/Summary'));
const Transaction = React.lazy(() => import('./components/home/pages/payIn/Transaction/Transaction'));
const Wallet = React.lazy(() => import('./components/home/pages/payOut/Wallet/Wallet'));
const Beneficiary = React.lazy(() => import('./components/home/pages/payOut/Beneficiaries/Beneficiary'));
const OutTransaction = React.lazy(() => import('./components/home/pages/payOut/OutTransaction/Transaction'));
const VendorWallet = React.lazy(() => import('./components/home/pages/vendor/Wallet/wallet'));
const OrderHistory = React.lazy(() => import('./components/Common/OrderHistory/OrderHistory'));
const TransferStatus = React.lazy(() => import('./components/home/pages/status/TransferStatus/TransferStatus'));
const BeneStatus = React.lazy(() => import('./components/home/pages/status/BeneStatus/BeneStatus'));
const DetailBulk = React.lazy(() => import('./components/Common/DetailBulk/DetailBulk'));
const BulkUploadDetails = React.lazy(() => import('./components/Common/DetailBulk/BulkUploadDetails'));

function setFavicon(imageUrl) {
  const head = document.querySelector('head');
  const favicon = document.createElement('link');
  favicon.rel = 'shortcut icon';
  favicon.type = 'image/x-icon';
  favicon.href = imageUrl;
  head.appendChild(favicon);
}


function App() {
  
  useEffect(()=> {
    const partnerName = sessionStorage.getItem("partnerName");
    document.title=(partnerName==="undefined" || !partnerName) ? "EasyPay":partnerName;
    const imageUrl = Favicon;
    setFavicon(imageUrl);
  }, [])

  const authCtx = useContext(AuthContext);
  const isLoggedIn = authCtx.isLoggedIn;

  return (
    <>
      <Suspense fallback={
        <Spinner />
      }>
        <Routes>
          <Route path="" element={<Navigate replace to={isLoggedIn ? 'home' : 'baas'} />} />
          { isLoggedIn && 
            <Route path="" element={<Home />}>
              <Route path="home" element={<HomePage/>} />
              <Route path="/payIn/summary" element={<Summary value={'payIn'} />} />
              <Route path="/payOut/summary" element={<Summary value={'payOut'} />} />
              <Route path="/payIn/transaction" element={<Transaction />} />
              <Route path="/payOut/beneficiary" element={<Beneficiary/>} />
              <Route path="/payOut/wallet" element={<Wallet />} />
              <Route path="/payOut/transaction" element={<OutTransaction />} />
              <Route path="/status/transfer" element={<TransferStatus />} />
              <Route path="/status/transfer/:transferId" element={<DetailBulk />} />
              <Route path="/status/details/:transferId" element={<BulkUploadDetails />} />
              <Route path="/status/beneficiary" element={<BeneStatus />} />
              <Route path="/change-password" element={<ChangePassword />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/vendor/ledger" element={<VendorWallet />} />
              <Route path="/order/:orderId" element={<OrderHistory />} />
            </Route>
          }
          { !isLoggedIn && 
            <Route path="baas"  element={<Baas />}>
              <Route path="/baas" element={<Navigate replace to="login"/>} />
              <Route path='login' element={<Login />} />
              <Route path='forget' element={<ForgetPassword/>} />
              <Route path='otp' element={<Otp/>} />
              <Route path='reset' element={<ResetPass/>} />
            </Route>
          }
          <Route path='*' element={<Navigate replace to={'/'} />} />
        </Routes>
      </Suspense>
    </>
  );
}
export default App;
