import React, {useState} from "react"

const AuthContext = React.createContext({
    token: '',
    isLoggedIn: false,
    login: (token) => {},
    logOut: ()=> {}
});

export const AuthContextProvider = (props) => {
    const initialToken = sessionStorage.getItem('newToken');
    const [token, setToken] = useState(initialToken);
    const userIsLoggedIn = !!token;

    const loginHandler = (token) => {
        setToken(token);
        sessionStorage.setItem('newToken', token);
    };

    const logoutHandler = () => {
        setToken(null);
        sessionStorage.removeItem('newToken');
        sessionStorage.removeItem('cpId');
        sessionStorage.removeItem('cpMstCode');
    };

    const contextValue = {
        token: token,
        isLoggedIn: userIsLoggedIn,
        login: loginHandler,
        logOut: logoutHandler
    };
    

    return (
        <AuthContext.Provider value={contextValue}>
            {props.children}
        </AuthContext.Provider>
    );
};

export default AuthContext;