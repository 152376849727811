import './Spinner.scss';

const Spinner = ()=> {
    return (
        <div className="spinner-container">
            <div className='loader'>
                <p className="my-p-loading">Loading...</p>
            </div>
            <div className="progress"></div>
        </div>
        
    );
}

export default Spinner;